<template>
  <!-- NAME[epic=admin] 用户分析 -->
  <div class="orderTest-container">
    <el-form
      ref="form"
      :model="form"
      inline
      label-position="right"
      label-width="120px"
    >
      <el-form-item label="" prop="">
        <el-date-picker
          v-model="time"
          type="daterange"
          range-separator="-"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-input v-model="form.keyword" placeholder="企业ID或名称"></el-input>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-input v-model="form.name" placeholder="联系姓名或手机号"></el-input>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-select
          v-model="form.type"
          clearable
          style="width: 140px"
          placeholder="类型"
        >
          <el-option
            v-for="(i, idx) in typeSelect"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-select
          v-model="form.is_close"
          clearable
          style="width: 140px"
          placeholder="期限状态"
        >
          <el-option
            v-for="(i, idx) in statusSelect"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-select
          v-model="form.vest_in"
          clearable
          style="width: 140px"
          placeholder="归属人"
        >
          <el-option
            v-for="(i, idx) in staffSelect"
            :key="idx"
            :value="i.id"
            :label="i.username"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button type="primary" @click="handleQuery">查询</el-button>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button @click="handleExport">导出</el-button>
      </el-form-item>
    </el-form>
    <el-table v-loading="loading" stripe :data="list">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        :width="item.width"
        align="center"
      ></el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.pageNo"
      :layout="layout"
      :page-size="form.pageSize"
      :total="total"
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
  </div>
</template>
<script>
  import { guishuSelectList } from '@/api/admin/userManagement'
  import { postAction } from '@/api/Employee'
  import { day_30 } from '@/utils/Time'
  export default {
    name: 'UserAnalysis',
    components: {},
    data() {
      return {
        time: [],
        form: {
          pageNo: 1,
          pageSize: 10,
        },
        loading: false,
        list: [],
        total: 0,
        layout: 'total, sizes, prev, pager, next, jumper',
        typeSelect: [
          // 1试用2正式
          {
            id: '1',
            name: '使用',
          },
          {
            id: '2',
            name: '正式',
          },
        ],
        statusSelect: [
          // 期限状态1已封存3正常4即将过期5已过期
          {
            id: '1',
            name: '已封存',
          },
          {
            id: '2',
            name: '正常',
          },
          {
            id: '3',
            name: '即将过期',
          },
          {
            id: '5',
            name: '已过期',
          },
        ],
        staffSelect: [],
        columns: [
          {
            prop: 'id',
            label: '客户ID',
            width: '',
          },
          {
            prop: 'dealer_name',
            label: '企业名称',
            width: '',
          },
          {
            prop: 'type_text',
            label: '类型',
            width: '',
          },
          {
            prop: 'end_time',
            label: '到期日期',
            width: '',
          },
          {
            prop: 'user_count',
            label: '员工总数',
            width: '',
          },
          // {
          //   prop: 'user_real_count',
          //   label: '正式账号',
          //   width: '',
          // },
          // {
          //   prop: '',
          //   label: '活跃员工',
          //   width: '',
          // },
          {
            prop: 'goods_count',
            label: '商品总数',
            width: '',
          },
          {
            prop: 'depot_count',
            label: '仓库总数',
            width: '',
          },
          {
            prop: 'cust_count',
            label: '终端总数',
            width: '',
          },
          {
            prop: 'order_count',
            label: '订单总数',
            width: '',
          },
          {
            prop: 'order_money',
            label: '销售总额',
            width: '',
          },
          {
            prop: 'display_count',
            label: '陈列总数',
            width: '',
          },
          {
            prop: 'cust_add_count',
            label: '新增终端',
            width: '',
          },
          {
            prop: 'order_add_count',
            label: '新增订单',
            width: '',
          },
          {
            prop: 'order_add_money',
            label: '新增销售金额',
            width: '',
          },
          {
            prop: 'display_add_count',
            label: '新增陈列',
            width: '',
          },
        ],
      }
    },
    computed: {},
    watch: {
      time(val) {
        if (val) {
          this.form.start_time = val[0]
          this.form.end_time = val[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    created() {
      this.initTime()
      guishuSelectList().then((r) => {
        this.staffSelect = r.data
      })
    },
    mounted() {},
    methods: {
      initTime() {
        // 默认一个月
        this.time = day_30()
        this.form.start_time = day_30()[0]
        this.form.end_time = day_30()[1]
      },
      async fetchData() {
        this.loading = true
        // /superAdmin/dealer-form/index
        let { data, totalCount } = await postAction(
          '/superAdmin/dealer-form/index',
          this.form
        )
        this.total = totalCount
        this.list = data
        this.loading = false
      },
      handleQuery() {
        this.form.pageNo = 1
        this.fetchData()
      },
      handleExport() {},
      handleCurrentChange(val) {
        this.form.pageNo = val
        this.fetchData()
      },
      handleSizeChange(val) {
        this.form.pageSize = val
        this.fetchData()
      },
    },
  }
</script>
<style lang="scss" scoped></style>
